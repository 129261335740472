<template>
  <section
    class="FourthSection mt-n1 pt-md-16 pa-8"
    style="background-color: #0b2449"
  >
    <div class="my-md-5 mb-5">
      <h1 class="FifthSection-h1 text-center mx-n5 mx-md-0">
        Transform into more than just a software engineer
        <br class="d-none d-md-flex" />emerge as a tech leader and architect a
        life you adore.
      </h1>
    </div>
    <v-row wrap class="mt-2 mx-md-12">
      <!--1st box-->
      <v-col cols="12" md="4" class="mt-md-10 mb-10">
        <v-card
          flat
          class="mx-n5 mx-md-0"
          style="background-color: #001738"
          rounded="lg"
          height="100%"
        >
          <v-img
            src="public/images/LandingPage/Rounded Rectangle.svg"
            class="ma-5"
          ></v-img>
          <v-card-title
            class="text-wrap text-start mt-6 ml-md-4 ml-2 landingPage-3-Title"
          >
            Internship programme at Diggaj Coder
          </v-card-title>

          <ul
            class="FourthSection-p px-md-16 px-10 mt-n2 pt-md-5 ml-md-n8 ml-n4"
          >
            <li class="my-5">
              <span>Hands-on Learning :</span> Gain practical experience through
              real-world projects and challenges.
            </li>
            <li class="my-5">
              <span>Mentorship : </span> Receive guidance and support from
              experienced professionals in the industry.
            </li>
            <li>
              <span>Flexible Schedule :</span> Adapt your internship around your
              academic commitments for convenience.
            </li>
          </ul>
          <v-card-text
            class="FourthSection-Learn ma-2 d-flex justify-start ml-md-4 mt-md-6"
          >
            Learn More About The Program
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" class="mt-md-10 mb-10">
        <v-card
          flat
          class="mx-n5 mx-md-0"
          style="background-color: #001738"
          rounded="lg"
          height="100%"
        >
          <v-img
            src="public/images/LandingPage/Rounded Rectangle (9).png"
            class="ma-5"
          ></v-img>
          <v-card-title
            class="text-wrap text-start mt-6 ml-md-4 ml-2 landingPage-3-Title"
          >
            How To Prepare
          </v-card-title>

          <ul
            class="FourthSection-p px-md-16 px-10 mt-n2 pt-md-4 ml-md-n8 ml-n4"
          >
            <li class="my-5">
              <span> Skill Development :</span> Enhance your skills in software
              development, coding, and problem-solving.
            </li>
            <li class="my-5">
              <span>Practice Regularly :</span> Apply what you learn through
              hands-on projects and exercises.
            </li>
            <li>
              <span>Join Communities :</span> Engage with communities related to
              your field to learn from others and stay updated on industry
              trends.
            </li>
          </ul>
          <v-card-text
            class="FourthSection-Learn ma-2 d-flex justify-start ml-md-4"
          >
            Start Coding Today
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" class="mt-md-10 mb-10">
        <v-card
          flat
          class="mx-n5 mx-md-0"
          style="background-color: #001738"
          rounded="lg"
          height="100%"
        >
          <v-img
            src="public/images/LandingPage/Rounded Rectangle (11).png"
            class="ma-5"
          ></v-img>
          <v-card-title
            class="text-wrap text-start mt-6 ml-md-4 ml-2 landingPage-3-Title"
          >
            Problem-Solving Workshops
          </v-card-title>

          <ul
            class="FourthSection-p px-md-16 px-10 mt-n2 pt-md-4 ml-md-n8 ml-n4"
          >
            <li class="my-5">
              <span>Live Coding Classes : </span> Engage in interactive sessions
              where participants code in real-time, guided by experienced
              instructors.
            </li>
            <li class="my-5">
              <span>Structured Schedule :</span> Sessions are structured to
              cover specific topics or projects, ensuring focused and productive
              learning.
            </li>
          </ul>
          <v-card-text
            class="FourthSection-Learn ma-2 d-flex justify-start ml-md-4 mt-md-12"
          >
            Sign Up Our Upcoming Workshops
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import { useMainStore } from "../stores/index";
export default {
  data() {
    return {
      store: useMainStore(),
    };
  },
};
</script>
<style scoped>
.FifthSection-h1 {
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
  color: #ffffff;
}

.landingPage-3-Title {
  color: #4dfaff;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
}

.FourthSection-p {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  list-style-type: none;
}

.FourthSection-p span {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
}

.FourthSection-Learn {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #2e81f7;
}

@media (max-width: 599.99px) {
  .FifthSection-h1 {
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    color: #ffffff;
  }

  .landingPage-3-Title {
    font-size: 16px;
    line-height: 22px;
  }

  .FourthSection-p {
    font-size: 13px;
    font-weight: 300;
    line-height: 20px;
  }

  .FourthSection-p span {
    font-size: 13px;
    font-weight: 700;
    line-height: 20px;
  }

  .FourthSection-Learn {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: #2e81f7;
  }

  .text6 {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 200;
    line-height: 20px;
    text-align: left;
  }

  .img6 {
    width: 296px;
    height: 163.31px;
  }
}
</style>
