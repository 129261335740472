<template>
  <section fluid class="Landing-SecondSection mt-n1 pt-md-16 pa-5">
    <v-row class="py-md-16 py-5 mx-n5 mx-md-16 px-md-16">
      <!-- Left Section -->
      <v-col cols="12" md="6" class="SecondSection-Left">
        <h1 class="SecondSection-Left-h1 text-center text-md-start">
          Full-Stack Development Course
        </h1>
        <v-img src="/images/LandingPage/Designer-7_1712512047965 1.png" alt="Course Image"
          class="SecondSection-Left-img mt-4 mt-md-5 ml-md-n2" width="520px"></v-img>
        <h3 class="SecondSection-Left-h3 mt-4 mt-md-5 text-center text-md-start">
          Flexible Payment Option
        </h3>
        <p class="SecondSection-Left-p mt-2 text-center text-md-start">
          No Upfront Fee: Pay only <b class=""> ₹1000 for seat booking </b> & 1st month fees ₹3000<br class="" /><br />
          EMI: Spread the remaining fees over <b>10 months (₹3,000 per month)</b><br class="" /><br />
          Post-Job Fee Payment Pay <b class="">90%</b> after securing a job, no ISA
          required<br /><br />
          Pay After Placement INR 7500/- Monthly Till 36 Month
        </p>
        <div class="text-center text-md-start">
          <v-btn v-if="!loggedIn" class="SecondSection-Left-Button mt-10" variant="flat" @click="onJoinfreeClick">
            Join for free
          </v-btn>
        </div>
        <p v-if="loggedIn" class="mt-10"></p>
      </v-col>

      <!-- Right Section -->
      <v-col cols="12" md="6" class="SecondSection-Right align-start mt-md-n2 px-5 px-md-0 pr-md-16">
        <div class="d-flex">
          <div>
            <h3 class="SecondSection-Right-h3 mt-4">Duration</h3>
            <p class="SecondSection-Right-p mt-md-2">10 Months</p>
          </div>
          <div class="ml-16">
            <h3 class="SecondSection-Right-h31 mt-4">Format</h3>
            <p class="SecondSection-Right-p1 mt-md-2">100% Online</p>
          </div>
        </div>
        <h3 class="SecondSection-Right-Master mt-8">Master Trainer</h3>
        <p class="SecondSection-Right-Personalized mt-md-2">
          Personalized learning with a Master Trainer
        </p>
        <h3 class="SecondSection-Right-Technologies mt-8">
          Technologies Covered
        </h3>

        <p class="SecondSection-Right-frontend mt-md-2">
          <b>Front-end </b>: HTML, CSS, JavaScript, Bootstrap, jQuery, React.js, Vue.js, GraphQL, JavaScript design
          patterns, system design basics, testable components, performance optimization, Ant Design , Vuetify <br />
          <b>Back-end </b> : Node.js, MongoDB, and SQL. It covers advanced topics in full-stack development, including
          front-end frameworks, back-end technologies, DevOps practices, advanced Node.js concepts like worker threads,
          AI integration, vector databases, and system design
        </p>





        <!--         <p class="SecondSection-Right-frontend mt-md-2">
          <b>Front-end:</b> React JS <br> <b>Intermediate:</b> JPA with Hibernate,
          HTML 5, CSS 3 (Bootstrap), JavaScript<br />
          <b>Back-end:</b> Core Java, DevOps, Angular, Database, and SQL
        </p> -->
        <h3 class="SecondSection-Right-Placement mt-8">Placement Assistance</h3>
        <p class="SecondSection-Right-Dedicated mt-md-2">
          Dedicated Placement Manager to assist with job placement
        </p>
        <h3 class="SecondSection-Right-Additional mt-8">Additional Benefits</h3>
        <p class="SecondSection-Right-Learning mt-md-2">
          - Learning Materials: Access to content with voiceovers<br />
          - Practical Experience: 15+ case studies and projects<br />
          - Support: Weekend doubt clearing sessions with SMEs<br />
          - Engage in real-world projects that allow you to apply the skills learned in a practical context, ensuring
          you have a portfolio to show potential employers<br />
          - Access to career services such as resume building, interview preparation, and job placement assistance to
          help you land your first job or advance your career
        </p>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { useMainStore } from '../stores/index'
export default {
  data() {
    return {
      store: useMainStore(),
    }
  },
  methods: {
    onJoinfreeClick() {
      this.store.registerDialog = true;
    }
  },
  computed: {
    loggedIn() {
      return this.store.isLoggedIn;
    },
  },
}
</script>

<style scoped>
.SecondSection-Left-img {
  display: block !important;
}

.Landing-SecondSection {
  background-image: url("/images/LandingPage/landingpage2-bagground.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
}

.SecondSection-Left-h1 {
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 2rem;

}

.SecondSection-Left-img {
  max-width: 100%;
  margin: auto;
}

.SecondSection-Left-h3 {
  color: #c1ff72;
  font-family: "Poppins", sans-serif;
  font-size: 1.5rem;
}

.SecondSection-Left-p {
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
}

.SecondSection-Left-Button {
  background-color: #2e81f7;
  color: white;
  width: 170px;
  height: 40px;
  border-radius: 6px;
  font-family: "Poppins", sans-serif;
  margin: auto;
}

/* Styles for the right section */
.SecondSection-Right-h3,
.SecondSection-Right-h31,
.SecondSection-Right-Master,
.SecondSection-Right-Technologies,
.SecondSection-Right-Placement,
.SecondSection-Right-Additional {
  color: #c1ff72;
}

.SecondSection-Right-p,
.SecondSection-Right-p1,
.SecondSection-Right-Personalized,
.SecondSection-Right-frontend,
.SecondSection-Right-Dedicated,
.SecondSection-Right-Learning {
  color: white;
  font-family: "Poppins", sans-serif;
  /* font-size: 1rem; */
  font-size: 18px;
}

p {
  margin-left: 0px !important;
  width: auto !important;
  height: auto !important;
}

.v-container {
  max-width: 100% !important;
}

@media (max-width: 599.99px) {

  .SecondSection-Left-h1 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 900;
    line-height: 28px;
    text-align: center;

  }

  .SecondSection-Left-h3 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
  }

  .SecondSection-Left-p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
  }

  .SecondSection-Left-Button {
    font-size: 0.875rem;
    width: 150px;
    height: 35px;
  }

  .SecondSection-Right-h3,
  .SecondSection-Right-h31,
  .SecondSection-Right-Master,
  .SecondSection-Right-Technologies,
  .SecondSection-Right-Placement,
  .SecondSection-Right-Additional {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;

  }

  .SecondSection-Right-p,
  .SecondSection-Right-p1,
  .SecondSection-Right-Personalized,
  .SecondSection-Right-frontend,
  .SecondSection-Right-Dedicated,
  .SecondSection-Right-Learning {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;

  }
}
</style>
