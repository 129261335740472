<template>
  <section class="FourthSection mt-n1 pt-md-16 pa-8" style="background-color: #0E2C58">
    <div class="d-flex justify-center ml-15 mt-2">
      <v-img id="menu-activator1" src="/images/LandingPage/Diggaj Coder.png" width="243px" height="54px" class="mr-10">
      </v-img>
    </div>
    <v-row wrap class="mt-2 mx-md-10">
      <!--1st box-->
      <v-col cols="12" md="4" class="mt-md-10 mb-10" v-for="(course, index) in courses" :key="index">
        <v-card flat rounded="lg" style="background-color: #001738" height="100%" class="mx-n5 mx-md-0">
          <v-img :src="course.courseImg" class="ma-5"></v-img>
          <v-card-title class="text-wrap text-center text-md-start mt-md-6 ml-md-4 landingPage-3-Title">
            {{ course.title }}
          </v-card-title>
          <ul class="FourthSection-p px-md-16 px-12 mt-n2">
            <li v-for="(higlight, indx) in course.highlights" :key="indx" class="my-5">
              {{ higlight }}
            </li>
          </ul>
          <div class="ma-5 ">
            <v-btn class="text-capitalize landingPage-3-btn " color="#2E81F7" block rounded="lg" height="45px"
              @click="onLearnMoreClick(course.title)">
              <!-- @click="onLearnMoreClick(course.title)" -->
              Learn More
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { useMainStore } from "../stores/index";
export default {
  data() {
    return {
      store: useMainStore(),
      courses: [
        {
          title: 'Full-Stack Development Basic',
          courseImg: '/images/LandingPage/Rounded Rectangle (6).png',
          highlights: ['Courses Duration: 10 Months', 'Monthly Payment : 3k for 10 month', 'Pay After Placement INR 7500/- Monthly Till 36 Month', 'HTML, CSS, ES6, , GraphQL , NODE.JS, EXPRESS.JS, MONGODB, REACT.JS ,VUE.JS , GraphDB ,  AWS Dev-ops Basics, Vector DB , LLM basics , ChatGPT-API intigrations']
        },
        {
          title: 'Full-Stack Development Advance',
          courseImg: '/images/LandingPage/LandingPageAdvance.png',
          highlights: ['Courses Duration: 7 Months', ' Monthly Payment : 3k for 7 month', 'Pay After Placement INR 5250/- Monthly Till 36 Month', 'Javascript Advance ,NODE.JS, EXPRESS.JS, MONGODB, AWS Dev-ops Basics, Vector DB , LLM basics , ChatGPT-API intigrations, REACT.JS, VUE.JS']
        },
        {
          title: 'Front End Development',
          courseImg: '/images/LandingPage/Rounded Rectangle (8).png',
          highlights: ['Course Duration : 4 Months', '  Monthly Payment : 5k for 4 months', 'Pay After Placement INR 4166/- Monthly Till 24 Months', 'HTML, CSS, ES6, JavaScript design patterns, system design basics,  TAILWIND CSS,REACTJS, NEXTJS, REACT NATIVE, GraphQL , Bootstrap , AntDesgin , Vuetify']
        }
      ]
    }
  },
  methods: {
    onLearnMoreClick(title) {
      const courseName = title
        .toLowerCase()         // Convert the entire string to lowercase
        .replace(/\s+/g, '-')  // Replace all whitespace (one or more spaces) with hyphens
        .trim();

      this.$router.push({ name: 'Course-courseName', params: { courseName } })
      // if (this.store.isLoggedIn) {
      //   this.$router.push({
      //     name: 'Profile', query: { comingFrom: 'landingPage', courseId: item }
      //   })
      // } else {
      //   this.store.registerDialog = true;
      // }

    }
  }
}
</script>
<style scoped>
.landingPage-3-Title {
  color: #4DFAFF;
  font-size: 17px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;

}

.FourthSection-p {
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  text-align: left;

}

.landingPage-3-btn {
  font-size: 13px;
  font-weight: 600;
  line-height: 19.5px;

}

@media (max-width: 599.99px) {
  .landingPage-3-Title {
    font-size: 16px;
    line-height: 22px;
  }

  .FourthSection-p {
    font-size: 13px;

  }

  .landingPage-3-btn {
    font-size: 13px;
    font-weight: 600;
    line-height: 19.5px;

  }
}

.Landing3 {
  /*card height */
  max-height: 100%;
  width: 366px;
}

.img3 {
  width: 326.25px;
  height: 180px;
  border-radius: 10px 0px 0px 0px;
}

@media (max-width: 599.99px) {
  .Landing3 {
    max-height: 100%;
    width: 328px;
  }

  .img3 {
    /*image box */
    width: 296px;
    height: 163.31px;
  }

  .ThirdSection-p {
    font-family: Poppins;
    font-size: 13px;
    /* font-weight: 300;
    line-height: 20px;
    text-align: left; */
  }

  .title {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    text-align: center;
  }

  .btn {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 600;
    line-height: 19.5px;
    text-align: center;
  }
}
</style>
