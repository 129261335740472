<template>
  <v-container fluid class="ma-0 pa-0">
    <v-carousel
      :continuous="true"
      cycle
      :show-arrows="false"
      hide-delimiter-background
      height="550"
    >
      <!--first carousel-->
      <v-carousel-item
        class="gopal"
        src="public/images/LandingPage/Designer-5_1712422325328 2 (2).png"
        cover
      >
        <v-container fluid>
          <!-- <section class="" style="height: 660px"> -->
          <!-- <v-row class="d-flex justify-center"> -->
          <v-row class="pa-0 pa-md-16 mt-9 mt-md-0 ml-md-5">
            <v-col cols="12" md="7" class="">
              <div
                class="text-section d-flex flex-column align-center align-md-start justify-center"
              >
                <v-title class="title12 mt-n6 align-self-start"
                  ><strong class="" style="color: aqua">JOIN</strong
                  ><strong class="text-lime"> DIGGAJCODER'S</strong>
                </v-title>

                <v-title class="title12-2 align-self-start">
                  Free Bootcamp Event
                </v-title>

                <v-sub-title
                  class="align-self-start subtitle12"
                  style="color: #ffde59"
                  >Highlights:</v-sub-title
                >

                <ul
                  class="ul12 text-white mt-md-8 mt-2 ml-5 text-wrap text-start"
                >
                  <li>Live interactive session with our expert instructors</li>

                  <li>
                    Overview of the latest trends in full-stack developements
                  </li>

                  <li>
                    Hands-on coding excercises with technologies like Vue.js,
                    GraphQL, and JavaScript , Nodejs
                  </li>

                  <li>Q&A session to clear all your doubts</li>
                </ul>
              </div>
              !<!--<div>
                 <v-btn @click="BootcapmPage()" rounded="lg" size="large" style="background-color: aqua; color: #14293c"
                  class="align-self-center align-self-md-start mt-5 mt-md-16 text-none">Join For Free</v-btn>
              </div> -->
              <v-btn
                v-if="!loggedIn"
                @click="onJoinfreeClick"
                rounded="lg"
                size="large"
                style="background-color: aqua; color: #14293c"
                class="align-self-center align-self-md-start mt-5 mt-md-5 ml-md-5 text-none"
                >Join For Free</v-btn
              >
            </v-col>
            <v-col cols="12" md="5" class="">
              <div class="ml-md-16 pl-md-16">
                <v-img
                  src="/images/LandingPage/Frame 427319054.svg"
                  class="image-section-3 ml-md-15 d-none d-md-flex"
                  contain
                ></v-img>
                <v-img
                  src="/images/LandingPage/Group 427319029.svg"
                  class="image-section-4 ml-md-n7 d-none d-sm-flex"
                  contain
                ></v-img>
                <v-img
                  src="public/images/LandingPage/Frame LandingPageSmall.svg"
                  class="image-section-small ml-md-n7 d-flex d-md-none mt-n4"
                  contain
                ></v-img>
              </div>
            </v-col>
          </v-row>
          <!-- </v-row> -->
          <!-- </section> -->
        </v-container>
      </v-carousel-item>

      <!--second carousel-->
      <v-carousel-item
        class="gopal"
        src="/images/LandingPage/Designer-5_1712422325328 2.png"
        cover
      >
        <v-container fluid>
          <v-row
            class="pa-0 pa-md-16 mt-9 mt-md-0 ml-md-5 d-flex flex-column-reverse flex-md-row"
          >
            <!-- Text and Button section -->
            <v-col cols="12" md="7" class="">
              <div
                class="text-section d-flex flex-column align-center align-md-start justify-center"
              >
                <h2 class="landingpage1-title" style="margin-bottom: 20px">
                  Empower Your Future Master FullStack Development Today
                </h2>

                <p class="Landing-para text-center text-md-start">
                  Join Now – Only ₹3,000 Monthly! Land Your Dream Job, Pay 90%
                  Later – No ISA Required
                </p>
                <v-btn
                  v-if="!loggedIn"
                  @click="onJoinfreeClick"
                  rounded="lg"
                  size="large"
                  style="background-color: aqua; color: #14293c"
                  class="align-self-center align-self-md-start mt-5 mt-md-16 text-none"
                  >Join For Free</v-btn
                >
              </div>
            </v-col>

            <!-- Image section -->
            <v-col cols="12" md="5" class="d-flex align-center">
              <div class="image-section flex-grow-1 mt-md-n10">
                <v-img
                  src="/images/LandingPage/Designer-5 1.png"
                  contain
                ></v-img>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-carousel-item>
      <!--thiird carousel-->
      <v-carousel-item
        class="gopal"
        src="public/images/LandingPage/Designer-5_1712422325328 2 (2).png"
        cover
      >
        <v-container fluid>
          <v-row class="pa-0 pa-sm-16 d-flex flex-column-reverse flex-md-row">
            <!-- Text and Button section -->
            <v-col cols="12" md="7" class="">
              <div
                class="text-section d-flex flex-column align-center align-md-start justify-center"
              >
                <h2 class="landingpage1-title">TECHNOLOGIES COVERED</h2>

                <p class="Landing-para mt-md-7">
                  <b> Front-End :</b> HTML, CSS, JavaScript, Bootstrap, jQuery,
                  React.js, Vue.js, GraphQL, JavaScript design patterns, system
                  design basics, Ant Design , Vuetify , Git , Tailwind <br />
                </p>
                <p class="Landing-para">
                  <b>Back-end :</b> Node.js, MongoDB, and SQL, DevOps ,
                  Javascript , Docker , Kubernetes , LLM , Worker threads, AI
                  Integration, vector databases, and system design , Scoket.IO ,
                  kafka
                </p>

                <v-btn
                  v-if="!loggedIn"
                  @click="onJoinfreeClick"
                  rounded="lg"
                  size="large"
                  style="background-color: aqua; color: #14293c"
                  class="align-self-center align-self-md-start mt-5 mt-md-16 text-none"
                  >Join For Free</v-btn
                >
              </div>
            </v-col>

            <!-- Image section -->
            <v-col cols="12" md="5" class="d-flex align-center">
              <div class="image-section-second flex-grow-1 mt-5">
                <v-img src="/images/LandingPage/Group 282.png" contain></v-img>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-carousel-item>

      <!-- <sharedLandingPagesLandingPage12 /> -->
    </v-carousel>
  </v-container>

  <!-- <Login @close="JoinClose()" /> -->
</template>

<script>
import { useMainStore } from "../stores/index";
export default {
  data() {
    return {
      store: useMainStore(),
    };
  },
  computed: {
    loggedIn() {
      return this.store.isLoggedIn;
    },
  },
  methods: {
    onJoinfreeClick() {
      this.store.registerDialog = true;
    },
    BootcapmPage() {
      this.$router.push({
        name: "Profile",
      });
    },
  },
};
</script>

<style scoped>
.landingpage1-title {
  color: #ffffff;
  font-size: 40px;
  font-weight: 900;
  line-height: 60px;
}

.Landing-para {
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 40.5px;
  text-align: left;
}

.Landing-para b {
  font-size: 18px;
  color: white;
  font-weight: 700;
  line-height: 40.5px;
}

.text-section {
  padding: 20px;
}

.image-section {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500.32px;
  height: 510.6px;
}

.image-section-second {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 400px;
  height: 400px;
}

.image-section-3 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 189.15px;
  height: 189.15px;
}

.image-section-4 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 372.83px;
  height: 280.35px;
}

/* .Landing-Main12 {
  margin-top: 130px;
  display: flex;
  justify-content: space-around;
  margin: 130px 0;
  align-items: center;
  background-image: url("");
  background-size: 100%;
  background-repeat: no-repeat;
  width: 1520px;
  top: 73px;
} */

.subtitle12 {
  font-family: Poppins;
  font-size: 17px;
  font-weight: 700;
  line-height: 25.5px;
  text-align: left;
  color: #ffde59;
}

.ul12 {
  font-family: Poppins;
  font-size: 17px;
  font-weight: 300;
  line-height: 30.5px;
  text-align: left;
}

.title12 {
  font-size: 46px;
  font-weight: 900;
  line-height: 60px;
  text-align: left;
}

.title12-2 {
  font-family: Poppins;
  font-size: 46px;
  font-weight: 300;
  line-height: 69px;
  text-align: left;
  color: #ffffff;
}

@media (max-width: 1279px) {
  /* Mobile view: stack image, text, and button vertically */
  /* .v-row {
    flex-direction: column-reverse;
  } */

  .text-section,
  .image-section,
  .image-section-second {
    text-align: center;
  }

  .text-section {
    padding: 0px;
  }

  .image-section {
    width: 200.41px;
    height: 200.99px;
  }

  .image-section-small {
    width: 320px;
    height: 120.32px;
  }

  .landingpage1-title {
    font-size: 24px;
    line-height: 24px;
  }

  .Landing-para {
    font-size: 14px;
    line-height: 24px;
  }

  .Landing-para b {
    font-size: 14px;
    line-height: 24px;
  }

  .image-section-second {
    width: 200px;
    height: 200px;
  }

  .ul12 {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 300;
  }

  .title12 {
    font-family: Poppins;
    font-size: 28px;
    font-weight: 900;
    line-height: 42px;
    text-align: left;
  }

  .title12-2 {
    font-size: 28px;
    font-weight: 300;
    line-height: 42px;
  }
}
</style>
